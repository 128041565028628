import React from 'react';
import Image from 'next/image';
import { MarketingPage } from './MarketingPage';
import { Meta } from '../Meta';
import { MarketingBanner } from "./MarketingBanner";
import CompanyLogosSection from "@/components/marketing/LogoGrid";
import { redirectToLoginOrDashboard } from "@/utils/url";
import Link from "next/link";

const styles = {
  gradientTextLg: "text-4xl sm:text-5xl md:text-6xl lg:text-7xl bg-gradient-to-tl from-[#9888FD] to-[#220E67] bg-clip-text text-transparent leading-tight sm:leading-tight md:leading-tight lg:leading-tight",
  gradientTextMd: "text-xl sm:text-2xl md:text-3xl lg:text-4xl bg-gradient-to-tl from-[#9888FD] to-[#220E67] bg-clip-text text-transparent leading-tight sm:leading-tight md:leading-tight lg:leading-tight",
};

const HeroContent = ({ latestPost }) => (
  <div className='flex flex-col items-center gap-4 sm:gap-6 md:gap-8 lg:gap-10 py-8 sm:py-6 md:py-10 lg:py-10 w-full max-w-5xl mx-auto'>
    <MarketingBanner title={latestPost.title} link={"/blog/" + latestPost.slug} />
    <h1 className={`font-semibold text-lupine-950 tracking-[-0.045em] text-center ${styles.gradientTextLg}`}>
      10x Faster Security Reviews for Modern Companies
    </h1>
    <p className="sm:text-xl md:text-2xl text-lupine-950 opacity-80 font-medium leading-8 sm:leading-9 md:leading-10 text-center">
      Purpose-built security questionnaire automation and trust center
    </p>
    <div className="flex flex-col sm:flex-row gap-4 mt-2">
      <Link
        href="https://calendly.com/naren-mano/wolfia-demo"
        className="px-6 py-3 text-base md:text-lg font-semibold text-white bg-lupine-600 rounded-full hover:bg-lupine-700 transition-all shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 duration-200 ease-in-out hover:text-white"
      >
        Schedule a demo
      </Link>
      <Link
        href={redirectToLoginOrDashboard()}
        className="px-6 py-3 text-base md:text-lg font-semibold text-lupine-600 bg-white border-2 border-lupine-200 rounded-full hover:bg-lupine-50 hover:text-lupine-700 hover:border-lupine-300 transition-all shadow-md hover:shadow-lg transform hover:-translate-y-0.5 duration-200 ease-in-out"
      >
        Start 14-day free trial
      </Link>
    </div>
  </div>
);

const HeroContainer = ({ latestPost }) => (
  <div className="flex flex-col w-full max-w-screen">
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <HeroContent latestPost={latestPost} />
    </div>
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <CompanyLogosSection />
    </div>
  </div>
);

const SectionTitle = ({ eyebrow, title }) => (
  <div className="flex flex-col flex-1 text-center max-w-sm lg:max-w-full gap-3 lg:gap-4 mb-8 lg:mb-0">
    <div className='text-xs lg:text-sm font-semibold text-lupine-600'>{eyebrow}</div>
    <h2 className={`font-semibold text-lupine-950 tracking-[-0.025em] ${styles.gradientTextMd}`}>
      {title}
    </h2>
  </div>
);

const VelocityBlock = () => (
  <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
    <div className="flex flex-col items-center gap-8 lg:gap-12">
      <SectionTitle
        eyebrow="Speed meets precision"
        title="Surpass human capabilities with intelligent AI responses"
      />
      <VelocityBentoBox />
    </div>
  </div>
);

const VelocityBentoBox = () => (
  <div className='flex justify-center items-center w-full'>
    <div className='rounded-3xl grid grid-cols-1 sm:grid-cols-2 gap-6 mt-5 sm:mt-10 lg:mt-20'>
      <VelocityBentoItem
        image="/images/home/landingPage_velocity_accurateAnswers.png"
        title="Streamline Security Reviews"
        content="Close deals faster with instant, AI-generated responses to security questionnaires and RFPs."
      />
      <VelocityBentoItem
        image="/images/home/landingPage_velocity_autofill.png"
        title="Enhance security compliance"
        content="Stay current with evolving security standards and provide up-to-date, traceable compliance information using AI."
      />
    </div>
  </div>
);

const VelocityBentoItem = ({ image, title, content }) => (
  <div className='grid gap-4 text-center bg-silvermist-50 p-6 sm:p-8 lg:p-10 justify-center items-center rounded-lg'>
    <Image
      src={image}
      alt={`Illustration for ${title}`}
      height={250}
      width={440}
      style={{
        filter: 'drop-shadow(0px 0px 0px rgba(33, 17, 90, 0.10)) drop-shadow(0px 1px 3px rgba(33, 17, 90, 0.10)) drop-shadow(0px 5px 5px rgba(33, 17, 90, 0.09)) drop-shadow(0px 12px 7px rgba(33, 17, 90, 0.05)) drop-shadow(0px 22px 9px rgba(33, 17, 90, 0.01)) drop-shadow(0px 34px 9px rgba(33, 17, 90, 0.00))',
      }}
    />
    <div className='max-w-96 mx-auto gap-4 sm:gap-6 flex flex-col'>
      <h3 className='text-lupine-950 font-semibold'>{title}</h3>
      <p className='text-sm text-lupine-950/60 font-medium'>{content}</p>
    </div>
  </div>
);

const AccuracyBlock = () => (
  <div className='rounded-3xl bg-silvermist-50 p-6 sm:p-8 lg:p-10 max-w-full sm:max-w-6xl mx-auto gap-8 sm:gap-12 lg:gap-16 flex flex-col items-center'>
    <SectionTitle
      eyebrow="Unmatched accuracy"
      title="Expert level responses" />
    <Image
      src="/images/home/landingPage_unmatched-accuracy-hero-image.svg"
      alt="Illustration for unmatched accuracy"
      height={439}
      width={1040}
      style={{ filter: 'drop-shadow(0px 0px 0px rgba(33, 17, 90, 0.10)) drop-shadow(0px 1px 3px rgba(33, 17, 90, 0.10)) drop-shadow(0px 5px 5px rgba(33, 17, 90, 0.09)) drop-shadow(0px 12px 7px rgba(33, 17, 90, 0.05)) drop-shadow(0px 22px 9px rgba(33, 17, 90, 0.01)) drop-shadow(0px 34px 9px rgba(33, 17, 90, 0.00)' }}
    />
    <AccuracyBentoBox />
  </div>
);

const AccuracyBentoBox = () => (
  <div className="rounded-3xl grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mt-5">
    <AccuracyBentoItem
      image="/images/home/landingPage_unmatched-accuracy-contextualResponses.svg"
      title="Customizable responses"
      content="Customize the tone and detail of responses to match your brand voice and client needs"
    />
    <AccuracyBentoItem
      image="/images/home/landingPage_unmatched-accuracy-upToDate.svg"
      title="Always Up-to-date"
      content="Our platform continually learns from your latest documentation, ensuring responses are always current and accurate"
    />
    <AccuracyBentoItem
      image="/images/home/landingPage_unmatched-accuracy-noHallucinations.svg"
      title="No hallucinations"
      content="Traceable answers with accurate references"
    />
  </div>
);

const AccuracyBentoItem = ({ image, title, content }) => (
  <div className="border border-silvermist-100 p-4 sm:p-6 gap-4 flex flex-col">
    <h3 className='text-lupine-950 font-semibold'>{title}</h3>
    <p className='text-sm text-lupine-950/60 font-medium'>{content}</p>
    <Image
      src={image}
      alt={"Illustration for " + title}
      height={400}
      width={500}
      style={{ filter: 'drop-shadow(0px 0px 0px rgba(33, 17, 90, 0.10)) drop-shadow(0px 1px 3px rgba(33, 17, 90, 0.10)) drop-shadow(0px 5px 5px rgba(33, 17, 90, 0.09)) drop-shadow(0px 12px 7px rgba(33, 17, 90, 0.05)) drop-shadow(0px 22px 9px rgba(33, 17, 90, 0.01)) drop-shadow(0px 34px 9px rgba(33, 17, 90, 0.00)' }}
    />
  </div>
);

const FeaturesModule = () => (
  <div className="bg-white pt-12 pb-12 md:pt-16 md:pb-16 lg:pt-20">
    <div className='flex flex-col mx-auto gap-20 sm:gap-28 md:gap-40 px-4 sm:px-6 lg:px-0'>
      <VelocityBlock />
      <AccuracyBlock />
    </div>
  </div>
);

const TestimonialBlock = ({ quote, name, title, description, image }) => (
  <div className='rounded-2xl border border-lupine-100 p-4 sm:p-6 md:p-10 flex flex-col flex-1 justify-between gap-4 sm:gap-6 min-w-72 md:min-w-120 max-w-144 bg-gradient-to-r from-lupine-50/20 via-lupine-100/20 to-lupine-200/20 shadow-md'>
    <div className='text-sm lg:text-base font-medium text-lupine-950/60 leading-6 sm:leading-7'>{quote}</div>
    <div className='flex justify-between items-center'>
      <div className="flex flex-col gap-1">
        <div className="text-sm lg:text-base font-semibold">{name}</div>
        <div className="text-xs sm:text-sm lg:text-md text-lupine-950/80 mt-1">{title}</div>
        {description && <div className="text-xs sm:text-sm lg:text-md text-lupine-950/80 mt-1">{description}</div>}
      </div>
      <div className="ml-4">
        <Image
          src={image}
          alt={"Portrait of " + name}
          height={64}
          width={64}
          style={{ borderRadius: '999px' }}
        />
      </div>
    </div>
  </div>
);

const TestimonialsModule = () => (
  <div className="bg-white md:px-0 py-12 md:py-16 lg:py-20">
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <div className='flex flex-col flex-1 gap-4 md:w-1/2 lg:w-full mx-auto text-center'>
        <div className='text-xs lg:text-sm font-semibold text-lupine-600'>What people are saying</div>
        <h2 className={`font-semibold text-lupine-950 tracking-[-0.0125em] ${styles.gradientTextMd}`}>Trusted by the fastest growing companies</h2>
      </div>
      <div className='max-w-full sm:max-w-5xl flex flex-wrap justify-center mx-auto gap-4 md:gap-6 lg:gap-10 mt-8 sm:mt-10'>
        <TestimonialBlock
          quote="Wolfia increased the speed of passing security review by 5x. AI compliance has been key for a number of our deals, and Wolfia has helped us turn compliance processes into a strength. Highly recommend the platform for anyone selling AI software to enterprises."
          name="David Paffenholz"
          title="Co-founder & CEO · PeopleGPT"
          image="/images/home/landingPage_testimonials_portrait_david.webp"
        />
        <TestimonialBlock
          quote="Wolfia helps us turn around security questionnaires in half the time while letting our team focus on higher leverage work. Would highly recommend!"
          name="Will Lawrence"
          title="Co-founder & CEO · Greenlite"
          image="/images/home/landingPage_testimonials_portrait_will.jpeg"
        />
        <TestimonialBlock
          quote="I hate filling out RFPs. As a GenAI company, I needed a tool to share my company's security and risk posture with larger customers. Wolfia helped me do that 10x faster!"
          name="Deepak Singla"
          title="Co-founder · Fini"
          image="/images/home/landingPage_testimonials_portrait_deepak.webp"
        />
        <TestimonialBlock
          quote="Through Wolfia, I'm able to quickly answer large questionnaires for many different clients. Wolfia has a unique approach to questionnaire automation. They use answers, and other sources of unstructured data, to build a living fact sheet about your security and compliance program. The answers that come from this flexible, dynamic approach are unlike anything else in the category."
          name="Rob Picard"
          title="Co-founder & CEO · Observa"
          description="Ex-Security lead at Vanta"
          image="/images/home/landingPage_testimonials_portrait_rob.jpeg"
        />
      </div>
    </div>
  </div>
);

const CTAModule = () => (
  <div className="bg-white pt-0 md:pt-16 pb-16 md:pb-24 lg:pb-40">
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <div
        className='max-w-50 md:max-w-7xl mx-auto rounded-2xl px-4 sm:px-6 py-8 sm:py-10 md:p-16 bg-lupine-50 border-lupine-100 border relative flex flex-col md:flex-row items-center md:items-start'
        style={{ boxShadow: '0px 0px 0px 0px rgba(25, 24, 33, 0.04), 0px 3px 6px 0px rgba(25, 24, 33, 0.04), 0px 11px 11px 0px rgba(25, 24, 33, 0.03), 0px 24px 14px 0px rgba(25, 24, 33, 0.02), 0px 42px 17px 0px rgba(25, 24, 33, 0.01), 0px 66px 18px 0px rgba(25, 24, 33, 0.00), 0px 0px 0px 1px #EDECF2 inset, 0px -1px 1px 0px #D6D4E3 inset, 0px -4px 52px 0px rgba(0, 0, 0, 0.05)' }}>
        <div className='flex flex-col items-center md:items-start gap-4 max-w-xlg relative text-center md:text-left'>
          <h2 className={`font-semibold text-lupine-950 tracking-[-0.0125em] ${styles.gradientTextMd}`}>
            10x Faster Security Reviews for Modern Companies
          </h2>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link
              href="https://calendly.com/naren-mano/wolfia-demo"
              className="px-6 py-3 text-base font-semibold text-white bg-lupine-600 rounded-full hover:bg-lupine-700 hover:text-white transition-all"
            >
              Schedule a demo
            </Link>
            <Link
              href={redirectToLoginOrDashboard()}
              className="px-6 py-3 text-base font-semibold text-lupine-600 bg-white border border-lupine-200 rounded-full hover:bg-lupine-50 hover:text-lupine-700 transition-all"
            >
              Start 14-day free trial
            </Link>
          </div>
        </div>
        <div className="relative md:absolute md:right-16 md:-bottom-4 mt-8 md:mt-0">
          <div className="mix-blend-color-burn flex flex-col blur-3xl rotate-45 -right-1/4 absolute opacity-75">
            <div className='w-[20rem] sm:w-[30rem] md:w-[40rem] h-24 bg-pineglade-500'></div>
            <div className='w-[20rem] sm:w-[30rem] md:w-[40rem] h-24 bg-lupine-500'></div>
          </div>
          <div className="z-0 pt-6 px-8 sm:px-16 max-w-[180px] sm:max-w-[240px] md:max-w-[400px] lg:max-w-[440px] xl:max-w-[460px] md:pt-0 md:px-0">
            <Image
              src={"/images/home/landingPage_CTA_gradientLogo.svg"}
              alt={"Wolfia logo"}
              height={240}
              width={240}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function LandingPage({ latestPost }) {
  return (
    <MarketingPage>
      <Meta title="Wolfia - Accelerate Security Reviews for Modern Companies">
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Wolfia - Accelerate Security Reviews for Modern Companies" />
        <meta property="og:description" content="Accelerate security reviews for modern companies with our purpose-built security review automation and trust center." />
        <meta property="og:image" content="/images/meta/og-image.png" />
        <meta property="og:url" content="https://wolfia.com" />
        <meta property="og:site_name" content="Wolfia" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:author" content="Wolfia Inc." />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@wolfia_app" />
        <meta name="twitter:title" content="Wolfia - Accelerate Security Reviews for Modern Companies" />
        <meta name="twitter:description" content="Accelerate security reviews for modern companies with our purpose-built security review automation and trust center." />
        <meta name="twitter:image" content="https://www.wolfia.com/images/meta/twitter-image.png" />
      </Meta>
      <HeroContainer latestPost={latestPost} />
      <FeaturesModule />
      <TestimonialsModule />
      <CTAModule />
    </MarketingPage>
  );
}
import React from 'react';
import Image from 'next/image';

const logos = [
  '/images/logos/companies/circle-ci.png',
  '/images/logos/companies/juicebox.png',
  '/images/logos/companies/mendable.png',
  '/images/logos/companies/greenlite.png',
  '/images/logos/companies/solveintelligence.png',
  '/images/logos/companies/finley.png',
  '/images/logos/companies/observa.png',
  '/images/logos/companies/infracost.png',
  '/images/logos/companies/meticulous.png',
  '/images/logos/companies/endorsed.png',
];

const LogoGrid = ({ itemHeight = 'h-20 sm:h-24 md:h-28 lg:h-32', gridGap = 'gap-2 sm:gap-3 md:gap-4 lg:gap-6' }) => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className={`grid grid-cols-2 ${gridGap} sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5`}>
        {logos.map((logo, index) => (
          <div
            key={index}
            className={`flex ${itemHeight} items-center justify-center rounded-lg transition-all duration-300 hover:bg-white group`}
          >
            <div className="relative w-full h-full flex items-center justify-center overflow-hidden rounded-lg">
              <Image
                src={logo}
                alt={`Company logo ${index + 1}`}
                width={200}
                height={100}
                className="w-[90%] h-[90%] object-contain transition-all duration-300 group-hover:scale-105"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const CompanyLogosSection = () => (
  <section className="py-8 sm:py-12 bg-transparent">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-center mb-6 sm:mb-8 bg-gradient-to-tl from-[#9888FD] to-[#220E67] bg-clip-text text-transparent">
        Trusted by the fastest growing companies
      </h2>
      <div className="w-full overflow-visible">
        <LogoGrid />
      </div>
    </div>
  </section>
);

export default CompanyLogosSection;
